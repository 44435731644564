.tableHeader {
  font-weight: 500;
}

.subtle {
  color: #495057;
  font-size: 14px;
}

.heavyInfo {
  font-size: 1.5rem;
  font-weight: 500;
}
