.patientHeaderName {
  font-weight: bold;
  font-size: 24px;
}

.patientSubHeader {
  font-size: 14px;
}

.editIcon:hover {
  cursor: pointer;
}
