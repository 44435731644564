.patientItemContainer {
  text-align: left;
}

.patientItemHeader {
  font-weight: bold;
  font-size: 18px;
  color: #3a2b35;
}

.patientItemSubHeader {
  font-size: 14px;
}

a.patientActive {
  border-right: 3px solid #ff15b0;
}
