.errorMessage {
  color: #ff6565;
  padding: 0.5em 0.2em;
  height: 1em;
  /* position: absolute; */
  font-size: 0.8em;
  white-space: nowrap;
}

.errorFormInput {
  border: 2px solid #ff6565;
}

.btn-form-primary::after {
  float: right;
  margin-top: 8px;
}