.btn-custom-primary {
  background: #ff15b0;
  border-radius: 100px;
  font-weight: bold;
  font-size: 12px;
  border: 1px solid #ff15b0;
  color: white;
  padding-left: 30px;
  padding-right: 30px;
}

.btn-custom-secondary {
  background: white;
  border-radius: 100px;
  font-weight: bold;
  font-size: 12px;
  border: 1px solid #ff15b0;
  color: #ff15b0;
  padding-left: 30px;
  padding-right: 30px;
}
