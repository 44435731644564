.loginContainer {
  background: white;
  border: 1px solid #f7f7f7;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.cardTitle {
  font-weight: 600 !important;
}

.forgotPasswordLink {
  font-size: 12px;
}
